@use 'common/src/lib/style/settings' as vb;

.app-navigation-shortcuts {
    .ui-icon-group-list {
        .ui-icon-group {
            width: 100%;
            justify-content: space-evenly;
        }
    }

    @include vb.mq('sm', 'max') {
        .ui-icon-group-list {
            flex-direction: column;

            .ui-icon-group {
                width: auto;
                padding: 0;

                .ui-icon-wrapper {
                    flex: 1 1 25%;
                }

                & + .ui-icon-group {
                    border-width: 1px 0 0;
                    margin: 16px 0 0;
                    padding: 16px 0 0;
                }
            }
        }
    }
}
